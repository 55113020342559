.container {
  background: #EFF2F5;
}

.section {
  background: #fff;
  margin-bottom: 20px;
  padding: 17px;

  &TitleRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}