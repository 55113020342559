@import "node_modules/@wfp/ui/src/globals/scss/_layout.scss";

.advancedSearchMenu {
  margin: 12px 0;
}

.summaryItem {
  flex-direction: column;

  .itemTitle {
    font-weight: 500 !important;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .summaryItemTag {
    white-space: break-spaces;
    line-height: 1.1rem;
    height: auto;
    padding-bottom: 3px;
    padding-top: 3px;
    border-radius: 8px;
  }
}

.summaryColumn {
  border-right: 2px solid #eee;
  height: 100%;
}

.rightColumn {
  padding-left: 40px !important;
  padding-right: 40px !important;

  @media screen and (max-width: map-get($breakpoints, "bp--sm--major")) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
