@import "scss/vars.scss";
@import "node_modules/@wfp/ui/src/globals/scss/_colors.scss";

html {
  overflow-x: hidden;
}

.section-title {
  background: #EFF2F5;
  border-radius: 0px 3px 3px 0px;
  padding: 12px;
  width: 100%;
  font-weight: 700;
  color: #031C2D;
  margin-bottom: 10px;
}

html,
body,
#root,
.App {
  height: 100%;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
}

.df {
  display: flex;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.fc {
  justify-content: center;
  align-items: center;
}

.f-100 {
  flex: 1 1 100%;
}

.loader {
  &.loader_sm {
    $w: 80px;
    width: $w;
    align-self: flex-start;
    height: $w;
    svg {
      stroke-width: 4px;
    }
  }
}

.fdrr {
  flex-direction: row-reverse;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}

.fdc {
  flex-direction: column;
}

.tar {
  text-align: right;
}

.fdr {
  flex-direction: row;
}

.fvc {
  align-items: center;
}

.fvs {
  align-items: flex-start;
}

.fve {
  align-items: flex-end;
}

.tac {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.divider {
  border-bottom: 1px solid #eee;
}

.jce {
  justify-content: flex-end;
}

$spaceX: 0, 1, 2, 4, 6, 8, 10, 12, 16, 24, 32;
$sides: "t", "b", "l", "r";
$sideToName: (
        "t": "top",
        "b": "bottom",
        "l": "left",
        "r": "right",
);

@each $size in $spaceX {
  .space-x-#{$size} > :not([hidden]) {
    margin-right: #{$size}px;
    &:last-child {
      margin-right: 0;
    }
  }

  @each $side, $correspSide in $sideToName {
    .m#{$side}-#{$size} {
      margin-#{$correspSide}: #{$size}px !important;
    }
    .p#{$side}-#{$size} {
      padding-#{$correspSide}: #{$size}px !important;
    }
  }

  .p-#{$size} {
    padding: #{$size}px !important;
  }

  .m-#{$size} {
    margin: #{$size}px !important;
  }

  .pv-#{$size} {
    padding-top: #{$size}px;
    padding-bottom: #{$size}px;
  }

  .ph-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }

  .mv-#{$size} {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }

  .mh-#{$size} {
    margin-left: #{$size}px;
    margin-right: #{$size}px;
  }
}

.text-02 {
  color: var(--text-02);
}

.jcsb {
  justify-content: space-between;
}

.text-03 {
  color: var(--text-03);
}

.icon-link {
  svg {
    fill: var(--interactive-01);
    width: 14px;
    height: 14px;
  }
}

.fspace {
  flex: 1 1 100%;
}

.fac {
  align-items: center;
}

.fsb {
  justify-content: space-between;
}

a.black-link {
  color: black;
}

.bold {
  font-weight: bold;
}

.no-select {
  user-select: none;
}

.svg-w-12 {
  width: 16px !important;
  height: 16px !important;
}

.vam {
  vertical-align: middle;
}

.oh {
  overflow: hidden;
}

.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mla {
  margin-left: auto;
}

.norelpos {
  position: static !important;
}

.jcs {
  justify-content: flex-start;
}

.no-margin {
  margin: 0 !important;
}

.fill-success {
  fill: var(--sequential-secondary-03);
}

.relpos {
  position: relative;
}

.is-12 {
  > svg {
    width: 12px !important;
    height: 12px !important;
  }
}

.text-error {
  color: var(--support-01) !important;
}

.icon-error {
  fill: var(--support-01);
  svg {
    fill: var(--support-01);
  }
}

.text-dec-circle {
  list-style: circle;
}

.error-text {
  color: var(--support-01);
  font-size: 12px;
}

div[role="dialog"].wfp--modal-container {
  overflow: auto;
  max-height: 90vh;
}

.modal_wider {
  .wfp--modal-inner {
    .wfp--modal-container {
      width: 800px;
    }
  }
}

.golden-icon {
  &:hover {
    color: gold !important;
    fill: gold !important;
  }
  .wfp--btn__icon {
    color: gold !important;
    fill: gold !important;
  }
}

.dib {
  display: inline-block;
}

.wfp--fieldset {
  legend.wfp--form-group__title {
    font-size: 16px;
  }
}

.no-outline {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}

.overflow-visible {
  overflow: visible !important;
  .wfp--modal-container {
    overflow: visible !important;
  }
}

.SingleDatePickerInput.SingleDatePickerInput__disabled.SingleDatePickerInput__showClearDate_4 {
  padding-right: 0 !important;
}

.text-italic {
  font-style: italic;
}

.wfp--search-magnifier {
  z-index: 0 !important;
}


#root .select__control {
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  font-size: 0.875rem;
  height: 39px;
  border: 1px solid var(--field-border-01);
  background: none;

  .select__indicator-separator {
    background-color: var(--field-border-01);
  }
}

#root, .wfp--modal {
  .DateRangePickerInput_arrow {
    display: none;
  }

  .d-none {
    display: none;
  }

  .flex-0 {
    flex: 0;
  }

  .separator {
    border: 1px solid #DFE3E6;
    height: 0;
    width: 100%;
  }

  .flex-initial {
    flex: initial;
  }
}

#root .wfp--input--invalid {
  .select__indicator-separator {
    background-color: var(--support-01);
  }
  .select__control {
    border-color: var(--support-01);
  }
}

.disabled-btn-container {
  display: flex;
  align-items: center;

  .wfp--btn {
    margin-right: 25px;
  }
}

.upload-document .wfp--breadcrumb-item {
  font-size: 18px;
  font-weight: 600;
}

.document-expired-banner {
  padding: 14px 35px;
  background: $color__red;
  position: absolute;
  color: #fff;
  top: 8px;
  left: -34px;
  z-index: 1;
  font-weight: 700;
  transform: rotate(-45deg);
}

.gap-15 {
  gap: 15px;
}

@media (max-width: 500px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 501px) {
  .only-mobile {
    display: none;
  }
}

.pub-date-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: -7px;
}

.pub-date-caption .wfp--btn {
  padding: 0 !important;
}

.DayPickerNavigation_button {
  display: none;
}

.two-lines-text-truncate {
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.09rem;
  overflow: hidden;
}

.tree-select--invalid .wfp--search-input {
  border-color: var(--support-01) !important;
}

.quill * {
  font-family: "Open Sans", sans-serif !important;
}