@import "node_modules/@wfp/ui/src/globals/scss/_colors.scss";

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: default !important;
  overflow: hidden;
  margin: 1rem 0;

  &ExpiredBanner {
    position: absolute;
    transform: rotate(-45deg);
    top: 7px;
    left: -28px;
    z-index: 1;
    padding: 10px 27px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background:  $color__red;
  }

  :global {
    .wfp--item--sidebar:last-child {
      border-bottom: none;
    }

    .wfp--item--sidebar {
      cursor: default;
    }

    .wfp--item__image {
      height: 100%;
    }
  }
}

.title {
  margin-bottom: 2px;
}

.itemFileInfoCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding: 8px;
}

.itemFileInfo {
  background: #eff2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  padding: 18px 4px;
  width: 70px;
  height: 80px;

  .itemIcon {
    svg {
      $w: 40%;
      transform: scale(90%);
    }
  }
}

.infoItem {
  margin-bottom: 4px;
  font-size: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}

.itemLang {
  font-weight: bold;
  font-size: 12px;
}

.itemInner {
  padding-left: 0;
  :global {
    .wfp--item__content {
      height: 100%;
      display: flex;
      flex-direction: column;

      .wfp--item__text-wrapper {
        flex: 1 1 100%;
      }
    }
  }
}

.bulkDownloadCheckbox {
  :global {
    .wfp--form-item.wfp--checkbox-wrapper {
      margin-bottom: 0;
    }
  }

  label {
    font-size: 14px;
  }
}

.subContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 720px) {
  .item {
    :global {
      .wfp--item__text-wrapper {
        flex-direction: column;
        align-items: flex-start;
      }

      .wfp--item__right {
        margin-top:7px;
        flex-direction: row;
        margin-left: 0;

        .df {
          flex-direction: row;
          gap: 5px;
        }
      }
    }
  }
}