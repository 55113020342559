.skeleton {
  $mm: 12px;
  background: var(--ui-02);
  border-radius: 4px;
  flex: 0 0 auto;
  &.skeletonRight {
    margin-left: auto;
  }
  &.lineSkeleton {
    height: 0.8rem;
    width: 100%;
    margin: 6px 0;
  }
  &.rectSkeleton {
    height: 2rem;
    width: 120px;
    display: inline-block;
    margin-right: $mm;
    margin: 12px 0;
  }
  &.pictureSkeleton {
    height: 400px;
    width: 100%;
    display: inline-block;
    margin-right: $mm;
    margin: 12px 0;
  }
}
