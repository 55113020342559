@import "node_modules/@wfp/ui/src/globals/scss/_layout.scss";
@import "~/scss/vars.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $headerZIndex;
}

.userNavWrapper {
  :global {
    .wfp--main-navigation__sub {
      @media screen and (min-width: map-get($breakpoints, "bp--lg--major")) {
        width: 20% !important;
      }
    }
  }

  .userSubNavigation {
    .userNavItem {
      margin-bottom: 4px;

      button {
        width: 100%;
        text-align: left;
      }

      svg {
        width: 16px;
        margin-right: 12px;
      }
    }
  }
}
