$p: 24px;
$innerCellPadding: 16px;

.noResultsMsg {
  width: 100%;
  flex: 1 1 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.header {
  padding: 0 $p;
  h1 {
    margin-bottom: 16px;
  }
}

.page {
  flex: 1 1 100%;

  .tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    :global {
      .wfp--tab-content {
        flex: 1 1 100%;
        margin-top: 0;
      }

      .wfp--tabs {
        margin: 0 $p;

        ul {
        }

        li {
          a {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

.tableWrapper {
  background: white;
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  width: fit-content;
  display: flex;
  flex-direction: column;

  :global {
    .wfp--pagination {
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }
  }
}

.table {
  white-space: nowrap;

  thead {
    font-size: 14px;
    border-bottom: 1px solid var(--ui-02);
    font-size: 16px;
    tr {
      border-bottom: none;
      th {
        background: white;
        border-bottom: none;
        border-right: none;
        padding: 0;
        > div {
          padding: 16px $innerCellPadding;
          box-sizing: border-box;
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
    }
  }

  tbody {
    font-size: 14px;

    a {
      text-decoration: none;
    }

    tr {
      border-bottom: none;
      padding: 0 $innerCellPadding;

      td {
        padding: 0;

        > div {
          display: flex;
          box-sizing: border-box;
          padding: 14px $innerCellPadding;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
}

.longCell {
  white-space: break-spaces;
}

.tab {
}

.tabContent {
  padding: $p;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
