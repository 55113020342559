.docSummaryList {
  background: var(--ui-02);
  padding: 20px;
  border-radius: 4px;
}

.docSummarySubList {
}

.listItem {
  margin-bottom: 0;

  .listItemTitle {
    font-size: 12px;
  }

  :global {
    .wfp--list__element__title {
      margin-bottom: 0;
    }
  }
}
