@import "../../scss/mixins.scss";

.documentPreview {
  font-size: 14px;
  color: var(--text-02);
  max-height: 100%;
  overflow: hidden;
  background: #eee;
  padding: 24px;
  border: 1px solid #eee;
  border-radius: 4px;
  @include cardBoxShadow;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 2.2/3;
}

.documentPreviewInner {
  overflow: hidden;
  width: 100%;
  overflow: hidden;
  @include cardBoxShadow;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.missing {
  background: white;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  $c: var(--text-01);
  color: $c;
  fill: $c;
  align-items: center;
  @include cardBoxShadow;
  width: 100%;
  aspect-ratio: 3/2;
}
