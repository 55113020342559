//@import "node_modules/@wfp/ui/src/globals/scss/vars.scss";
//@import "node_modules/@wfp/ui/src/components/Button/_mixins.scss";

.iconButton {
  //@include button-theme(transparent, transparent, var(--interactive-01), transparent, transparent);
  padding: 0 6px !important;
  user-select: none;
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;
  background: transparent;

  svg {
    padding: 0 !important;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: default;
  }
}
