$s: 14px;

.button {
  // Override the behaviour where the button would change it's
  // margin depending on whether it's primary or secondary
  margin-left: 0 !important;
  display: flex;
  align-items: center;
  span {
  }
  svg {
    width: $s;
    height: $s;
    margin-left: 6px;
  }
}

.active {
  svg {
  }

  &:focus {
  }
}
