@import "~/scss/vars.scss";

$ip: 16px;

.modal {
  $p: 8px;

  position: fixed;
  bottom: $p;
  right: $p;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: $bulkDownloadZIndex;

  > a {
    display: flex;
    flex-direction: column;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.docList {
  padding: 0 $ip;
  flex: 1 1 100%;
  max-height: 320px !important;
  overflow-y: auto;
}

.actions {
  padding: $ip;
  button {
    width: 100%;
  }
}

.listItem {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  :global {
    .wfp--list__element__title {
      margin-bottom: 0;
    }
  }

  button {
    outline: none;
    border: none;

    svg {
      width: 8px;
      height: 8px;
    }
  }
}
