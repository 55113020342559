.container {
  position: relative;
}

.content {
  min-height: 70px;
}

.addLinkButton {
  position: absolute;
  right: 0;
  top: -40px
}