.leftColumnItems {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.shortcutListItem {
  margin-right: 6px;
  font-size: 14px;
  &:last-child {
    margin-bottom: 0;
  }
}

.pickerInput {
  margin-bottom: 12px;
}
