@import "node_modules/@wfp/ui/src/globals/scss/_colors.scss";
@import "node_modules/@wfp/ui/src/globals/scss/_layout.scss";
@import "../../scss/vars.scss";
@import "../../scss/mixins.scss";

$searchWrapperBg: #eff2f5;
//$contentBg: #f5f5f5;
$contentBg: #fff;

$fullPadding: 24px;
$leftColWidth: 50%;
$leftColWidthLarge: 35%;
$fullWidth: 100%;
$tabletWidth: 70%;
$leftColMaxWidth: 800px;

.selectPreviewHelperWrapper {
  display: flex;
  height: 100%;
  position: relative;
  background: white;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  .col {
    flex: 1 1 50%;
    padding: 12px;
    display: flex;
    flex-direction: column;
  }

  .selectPreviewHelperWrapperLeft {
  }

  .selectPreviewHelperWrapperRight {
  }
}

.selectPreviewHelper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 480px;
  padding: 64px;
  text-align: center;
  line-height: 1.3rem;
  font-size: 0.9rem;
  background: white;
  display: flex;
  flex-direction: column;
  color: var(--ui-05);
}

.searchWrapper {
  background: $searchWrapperBg;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  padding-left: $fullPadding;
  padding-right: $fullPadding;

  @media screen and (min-width: map-get($breakpoints, "bp--lg--major")) {
    justify-content: flex-start;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.search {
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex: 1 1 $fullWidth !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-sizing: border-box !important;
  max-width: inherit !important;

  @media screen and (min-width: map-get($breakpoints, "bp--sm--major")) {
    flex: 0 0 $tabletWidth !important;
  }

  @media screen and (min-width: map-get($breakpoints, "bp--lg--major")) {
    flex: 0 0 $leftColWidth !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    padding-left: $fullPadding !important;
    max-width: $leftColMaxWidth !important;
  }
}

.leftCol {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: center;
  padding-left: $fullPadding;
  padding-right: $fullPadding;

  @media screen and (min-width: map-get($breakpoints, "bp--lg--major")) {
    justify-content: flex-start;
    flex: 0 1 $leftColWidth !important;
    max-width: $leftColMaxWidth !important;
    padding-right: 0 !important;
  }
}

.leftColContent {
  flex: 0 1 $fullWidth;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: map-get($breakpoints, "bp--sm--major")) {
    flex: 0 0 $tabletWidth !important;
  }

  @media screen and (min-width: map-get($breakpoints, "bp--lg--major")) {
    flex: 1 1 100% !important;
    padding-right: 0;
  }

  :global {
    .wfp--item {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.page {
  position: relative;
}

.content {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: $contentBg;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 24px;
  padding-left: 0;
  padding-right: 0;

  @include breakpoint(bp--xs--major) {
    justify-content: center;
  }

  @include breakpoint(bp--lg--major) {
    justify-content: flex-start;
  }
}

.latestUploadsList {
  width: 100%;
  flex: 1 1 100%;
  height: 100%;
  padding-left: 26px !important;
}

.sidebarItem {
  &_active {
    // background: $color__gray-10;
  }
}

.noResultsMsg {
  background: $contentBg;
  flex: 1 1 100%;
}

.searchResultsHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 12px;
  align-items: center;

  .sorter {
    flex: 0 1 200px;
    font-size: 14px;
  }
}

.documentViewWrapper {
  position: fixed;
  right: 1vw;
  top: 14vh;
  width: inherit;
  height: 80vh;
  overflow: hidden;
  border-radius: 8px;
  @include cardBoxShadow;
  background: white;
  padding: 24px;

  @media screen and (max-height: 700px) {
    top: 14vh;
    right: 0.4vw;
  }
}

.documentViewColumn {
  height: 100%;
  position: static !important;
  z-index: $documentViewZIndex;
  flex: 0 0 38%;
  width: 48%;

  @media screen and (max-width: 1200px) {
    flex: 0 0 40%;
    width: 40%;
  }

  @media screen and (max-width: map-get($breakpoints, "bp--lg--major")) {
    display: none;
  }
}
