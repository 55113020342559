.invalidText {
  color: var(--support-01);
  font-size: 12px;
  font-weight: bold;
}

.customFormGroup {
  margin-bottom: 2rem;

  :global {
    .wfp--form-requirement {
      margin-top: 0.6rem;
    }

    .wfp--fieldset__content > * {
      margin-bottom: 0;
    }

    .wfp--form-item {
      margin-bottom: 0;
    }
  }
}
