@import "../../scss/mixins.scss";
@import "../../scss/vars.scss";

$ph: 12px;
$suggestionsPadding: 16px;

.suggestionsListTitle {
  // margin-top: $suggestionsPadding;
  // margin-bottom: $suggestionsPadding;
  padding: $suggestionsPadding;
}

.suggestionsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $wrp-z-index;
}

.suggestionsArrow {
  margin-right: $suggestionsPadding;
}

.suggestions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: $wrp-z-index + 1;
  max-height: 280px;
  height: fit-content;
  overflow: auto;
  @include cardBoxShadow;

  .suggestionItem {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    justify-content: flex-start;
    padding-left: $ph;
    padding-right: $ph;
    font-weight: 400 !important;
    flex: 1 1 100%;
    color: var(--text-01);
    width: 100%;
    border: none;
    outline: none;
    justify-content: space-between;
    padding: 0 $suggestionsPadding;

    border-radius: 0;
    display: flex;
    flex-direction: row;
    fill: var(--ui-05);

    $iconSize: 14px;

    svg {
      width: $iconSize;
      height: $iconSize;
    }

    button {
      padding: 0 !important;
      color: var(--text-01);
    }

    &:hover {
      background: var(--ui-02);
      button > svg {
        fill: var(--ui-05);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
