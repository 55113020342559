@import "node_modules/@wfp/ui/src/globals/scss/_layout.scss";

.uploadForm {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.langVersionModal {

  :global .wfp--modal-container {
    overflow: visible !important;
  }
}

.formContainer {
  padding: 24px !important;
}

.uploadArea {
  background: var(--ui-02);
  cursor: pointer;
  height: 100%;
  flex: 1 1 100%;

  @include breakpoint(bp--xs--major) {
    margin-bottom: 24px;
  }

  @include breakpoint(bp--md--major) {
    flex-basis: 100%;
    height: 100%;
  }

  @include breakpoint(bp--lg--major) {
    flex-basis: 500px;
    height: 500px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-bottom: 24px;
  }

  &_selectedFiles {
    .uploadAreaInner {
      background: var(--ui-04);
    }
  }

  .uploadAreaInner {
    height: 100%;
    border: 2px dashed var(--interactive-01);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    text-align: center;
  }
}

.formGroup {
  margin-bottom: 8px;
}

.leftCol {
  background: var(--ui-02);
  display: flex;
  flex-direction: column;
  padding: 24px !important;
}

.confirmationModal {
  width: 100%;
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.modal {
  legend {
    font-size: 14px;
  }
}

.linkRelDocModal {
  width: 500px;
  max-width: 100%;

  legend {
    font-size: 14px;
  }
}

.relDocsList {
  padding: 16px 0;
}

.listItem {
  margin-bottom: 10px;
  list-style-type: none;
}

.listItemProtocol {
  line-height: 17px;
}

.uploadAreaModal {
  height: 160px;
}
