@import "../../scss/vars.scss";
@import "../../scss/mixins.scss";

.leftCol,
.rightCol {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  overflow-y: auto;
  position: relative;
  background: inherit !important;
}

.leftCol {
  margin-right: 24px;
  flex: 1 1 50%;
  height: 100%;
}

.rightCol {
  flex: 1 1 50%;
}

@media (max-width: 1200px) {
  .rightCol {
    width: 0;
    flex: 0;
    height: 0;
  }
}

.documentView {
  flex: 1 1 100%;
  background: #fff;
  width: 100%;
  height: 100%;
}

.docViewAction {
  font-size: 12px;
  $p: 4px;
  margin-right: $p;
  margin-bottom: $p;

  &:last-child {
    margin-right: 0;
  }
}

.documentViewTitle {
  h3 {
    margin: 0;
    margin-bottom: 8px;
  }
}

.documentInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
}

.documentInfoItem {
  margin-bottom: 18px;
  text-align: left;

  .documentInfoItemLabel {
    margin-bottom: 6px;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
