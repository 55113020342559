@import "node_modules/@wfp/ui/src/globals/scss/_layout.scss";
@import "node_modules/@wfp/ui/src/globals/scss/_colors.scss";

.page {
  box-sizing: border-box;
  padding: 32px !important;
  flex-shrink: 1;
  position: relative;
}

.content {
  position: relative;
  overflow: hidden;
}

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  column-gap: 30px;
}

.docAction {
  margin-right: 6px;
  font-size: 12px;
  margin-bottom: 6px;

  &:last-child {
    margin-right: 0;
  }

  button {
    font-size: 12px;
  }
}

.documentRelated {

  &Item {
    display: flex;
    align-items: center;
    gap: 5px;

    &Link {
      text-decoration: none;
    }
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 20px;
  }
}

.documentPreview {
  width: 100%;
  height: auto;
  max-width: 550px;
  min-width: 440px;

  @media screen and (max-width: map-get($breakpoints, "bp--lg--major")) {
    display: none;
  }
}

.documentInfoItem {
  font-size: 14px;
  margin-bottom: 18px;

  .documentInfoItemLabel {
    font-size: 14px;
  }
}

.documentTranslation {
  margin-top: 6px;
  margin-bottom: 20px;

  &Label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}

.docInfoWrapper {
  flex-grow: 2;
}

.docPreviewWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  height: inherit;

  :global {
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.documentViewTitle {

  > h3 {
    margin-top: 0;
  }
}

.deletionModal {
  width: 400px;
}
