.langItem {
  display: inline-block;
  margin-right: 6px;
  padding-top: 0px;

  &:last-child {
    margin-right: 0;
  }

  &_selected {
    color: black;
  }

  text-decoration: none;
}
