@import "../../scss/mixins.scss";

.summaryItemTag {
  padding: 4px 12px;
  button {
    padding: 0 !important;
    margin-left: 6px;
    svg {
      $s: 8px;
      width: $s !important;
      height: $s !important;
      margin: 0 !important;
      fill: var(--ui-06);
    }
  }
  :global {
    button.wfp--btn {
      padding-right: 0 !important;
    }
  }
}

.searchInput {
  :global {
    .wfp--search-input:focus {
      // 1px solid var(--field-border-01)
      outline: none !important;
    }
  }
}

.advancedSearchFiltersDisplay {
  display: flex;
  flex-wrap: wrap;

  :global {
    .wfp--tag {
      margin: 2px !important;
    }
  }
}
