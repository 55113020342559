@import "node_modules/@wfp/ui/src/globals/scss/_colors.scss";
@import "~/scss/mixins.scss";
@import "~/scss/vars.scss";

.wrapper {
  position: relative;
}

.options {
  z-index: $treeSelectZIndex;
}

$treeBorderWidth: 1px;
$treeBorder: $treeBorderWidth solid $color__gray-30;
$distanceBetweenCheckboxAndGuide: 6px;
$h: 24px;
$halfOfCheckboxHeight: $h / 2;
$subtreeLeftMargin: 14px - $treeBorderWidth;

.topicSubtree {
  border-left: $treeBorder;
  margin-left: $subtreeLeftMargin;
  margin-top: $distanceBetweenCheckboxAndGuide + $halfOfCheckboxHeight;
  width: 100%;
}

.rootTree {
  position: relative;
  &AngleIcon {
    cursor: pointer;
  }
}

.topicSubtreeRoot {
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  .topicSubtreeRootCheckbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .topicSubtreeRootArrow {
      margin-right: $distanceBetweenCheckboxAndGuide;
      &::before {
        content: "";
        position: absolute;
        border-bottom: $treeBorder;
        height: 17px;
        box-sizing: border-box;
        width: inherit;
      }
    }
  }
}

.treeSelectCheckBoxWrapper {
  flex: 1;
  max-width: 90%;
  display: flex;
  justify-content: start;
  align-items: top;
  cursor: pointer;

}

.checkboxWrapper {
  width: 100%;

  :global {
    transform: translateY(50%);
    .wfp--form-item {
      margin-bottom: 0;
    }
  }
}

.emptyMessage {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.resultsContainer {
  position: absolute;
  top: 0;
  z-index: 9999999;
  left: 0;
  padding: 8px;
  background: white;
  border: 1px solid var(--ui-02);
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
  width: 100%;
  @include cardBoxShadow;
}
